// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/variables";
@import "theme/aahk";
@import "app/core/css/global";

@import "app/core/css/icon";
@import "./theme/icon";
@import "./assets/css/animate";
// http://ionicframework.com/docs/v2/theming/
// APP - Style START
//make desktop a mobile size, iphone 6+

//// Global
.wechat ion-icon[name="arrow-back"] {
  // display: none;
}
// ios10+ force 缩放
* {
  touch-action: manipulation;
}
.wechat ion-title {
  display: none;
}

// lock the back screen //
.scrollLock {
  .scroll-content {
    overflow-y: hidden;
  }
}

//// Theme style ////
.content {
  background: var(--ion-primary-contrast);
}

//// header ////

//// search bar
ion-toolbar {
  .search-bar-box {
    margin-left: 30px;
    text-align: right;
    ion-button.search-btn {
      max-height: 30px;
      // --color: var(--ion-color-medium);
      // --background: #fff;
      // --background-activated: #fff;
    }
  }
}

//// nav bar icon
.h-nav {
  align-self: center;
  flex-shrink: 0;

  ion-icon {
    position: relative;
    padding-right: var(--main-padding-sm);
  }

  .news {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 11px;
    height: 11px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--ion-color-success);
  }
  #notifications-badge {
    position: absolute;
    top: -0;
    right: 0;
    font-size: var(--main-sm-text);
  }
}

//// toast controller ////
ion-toast.toast-box {
  height: calc(100% - 45px) !important;
}

// toast style//
.normal-toast,
.toast-box {
  .toast-wrapper {
    background: #fff0bd;
    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1), 0 0px 16px rgba(0, 0, 0, 0.16);

    .toast-message {
      color: #666;
      display: inline-flex;
      align-items: center;
      padding: 0.8rem 1.6rem;

      &:before {
        color: var(--ion-color-success);
        font-size: 2.4rem;
        padding-right: 8px;
        display: inline-block;
        font-family: "Ionicons";
        content: "\f14a";
      }
    }
  }
}

//// dialog popup
.no-bgColor {
  .modal-wrapper {
    --background: transparent;
  }

  .ion-page {
    --background: transparent;
  }

  .no-bg {
    --background: transparent;
  }
}

//// Text style ////
.sm-font-size {
  font-size: var(--main-sm-text);
}

.md-font-size {
  font-size: var(--main-mid-text);
}

.lg-font-size {
  font-size: var(--main-lg-text);
}

.xlg-font-size {
  font-size: var(--main-xlg-text);
}

.break-line {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal !important;
}

.line-clamp {
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  text-overflow: ellipsis;
  max-height: 2.3em;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

//// shadow
.cardShadow {
  box-shadow: var(--main-card-shadow);
}

.txtShadowOnBlack {
  text-shadow: 2px 2px 5px #cecece;
}

.textShadowOnGradient {
  text-shadow: 0 0.5px #e6e6e4, 0.5px 0 #696969, 0.5px 0px #8e8e8e,
    1px 1.5px #000000;
}

.lgBtnShadow {
  box-shadow: var(--main-key-btn-shadow);
}

//// custom dialog ////

.custom-dialog-wrapper {
  background: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

//// pickup info ////
.pickup-info-box {
  padding: var(--main-padding) 0;
  border-top: 1px solid var(--ion-color-light);
  border-bottom: 1px solid var(--ion-color-light);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  .pickup-info-item {
    display: inline-flex;
    align-items: center;
  }
  ion-icon {
    font-size: 28px;
    width: 48px;
  }
  .info-item-inner {
    p {
      margin: 0;
      &:first-child {
        font-size: var(--main-sm-text);
      }
      &:last-child {
        font-size: var(--main-lg-text);
        font-weight: bold;
        color: var(--ion-color-dark);
      }
    }
  }
}

// with OTP //
.custom-passcode-dialog {
  flex: 0 0 75vw;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 41px 1px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px 8px;
  position: relative;

  .close-icon {
    position: absolute;
    font-size: 4rem;
    color: #222;
    background: #fff;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 0.1rem #ccc solid;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    top: -17px;
    right: -17px;
  }

  .text-box {
    p {
      text-transform: uppercase;
      text-align: center;
      font-size: 1.6rem;
      color: var(--ion-color-dark);
      margin-bottom: 0;
    }
  }

  .input-box {
    padding: 1rem;

    input {
      //border: none;
      width: 100%;
      font-size: 3.3rem;
      font-weight: bold;
      padding: 0.5rem;
      text-align: center;
      -webkit-box-shadow: 0 0 2rem 0.1rem #ccc;
      -moz-box-shadow: 0 0 2rem 0.1rem #ccc;
      box-shadow: 0 0 2rem 0.1rem #ccc;
      overflow-y: scroll;
    }

    .error-text-box {
      height: 2em;

      p {
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin: 0;
        padding: 0;
        color: var(--ion-color-danger);
      }
    }
  }

  .btn-box {
    display: inline-flex;
    width: 100%;

    button {
      flex: 1;
    }

    .confirm-btn {
      background: var(--ion-color-success);
      text-transform: uppercase;
      color: var(--ion-color-success-contrast);
      transition: 0.5s all ease-in-out;
    }
  }

  .close-icon:active,
  .confirm-btn:active {
    transform: scale(0.9);
  }
}

//// Sold Out Box
.sold-out-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  p {
    color: var(--ion-color-danger);
    font-size: var(--main-mid-text);
    background: #fff;
    border: 1px solid var(--ion-color-danger);
    padding: var(--main-padding-sm) var(--main-padding);
    border-radius: var(--main-sm-btn-radius);
  }

  span {
    color: var(--ion-color-danger);
    font-size: var(--main-mid-text);
    background: #fff;
    border: 1px solid var(--ion-color-danger);
    padding: var(--main-padding-sm) var(--main-padding);
    border-radius: var(--main-sm-btn-radius);
  }
}

//// NO OTP
.custom-dialog {
  flex: 0 0 85vw;
  height: 205px;
  background: var(--ion-color-primary-contrast);
  border-radius: 8px;
  box-shadow: 0px 0px 41px 1px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;

  .text-box {
    p {
      text-align: center;
      font-size: 1.7rem;
      color: var(--ion-color-dark);
    }
  }

  .input-box {
    padding: 0 3rem;
    flex: 1;

    input {
      width: 100%;
      font-size: 2.3rem;
      font-weight: bold;
      padding: 1rem;
    }

    .error-text-box {
      height: 22px;

      p {
        color: var(--ion-color-danger);
        margin: 0;
        padding-top: 0.5rem;
      }
    }
  }

  .btn-box {
    border-top: 1px solid var(--ion-color-light);
    margin-top: 1rem;
    display: inline-flex;
    width: 100%;

    button {
      border-radius: 0;
      flex: 1;
    }

    .confirm-btn {
      border-left: 1px solid var(--ion-color-light);
      font-weight: bold;
    }
  }
}

//// toolbar style ////

.trans-toolbar {
  &.toolbar:first-child {
    .toolbar-background {
      border-width: 0;
    }
  }

  .toolbar-background {
    background: transparent;
  }
}

.back-btn {
  //// resize the back button to system one ////
  padding: 0 2px;

  p {
    font-size: var(--main-mid-text);
    margin: 0;
    font-weight: 600;
  }

  &.dark {
    p,
    ion-icon {
      color: var(--ion-color-dark);
    }
  }
}

//// in page header
.inPage-header-box {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;

  .back-btn-box {
    background: #fff;
    border: none;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    overflow: hidden;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    outline: none;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    .back-btn {
      --padding-start: 0;
      --padding-end: 0;
      --color: var(--ion-color-dark);

      ion-icon {
        font-size: 2rem;
      }
    }
  }
}

//// Footer ////

.footer-back-box {
  background: #ccc;
  color: #000;
  display: flex;
  align-items: center;
  padding: 0 10px;

  ion-icon {
    font-size: 3rem;
    line-height: 1;
  }
}

.footer-content-box {
  padding: 0 20px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.05rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  .action-btn-box {
    display: inline-flex;
    align-items: center;
    margin-left: 2rem;

    p {
      transform: translateX(5px);
    }

    ion-icon {
      padding-left: 0.4rem;
      transform: translateY(1px);
      font-size: 2.4rem;
      line-height: 1;
    }
  }
}

ion-footer {
  .toolbar-background {
    background-color: var(--ion-color-secondary);
  }
}

.btn-order-panel.activated {
  background: #333;
}

.btn-order-panel.activated {
  background: #333;
}

#additional-info-box {
  text-align: center;

  p {
    color: var(--ion-color-danger);
    margin: 0.5em 1em;
  }
}

//// Payment page ////
// footer button //
.bottom-btn-wrapper {
  padding: 10px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #f2f2f2;
}

//Item Summary//

@import "theme/overwrite";
// .content{background: #EDE8DF;}
#apple-pay-button {
  //display: none;
  background-color: black;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-size: 100% 100%;
  background-origin: content-box;
  background-repeat: no-repeat;
  width: 100%;
  height: 44px;
  padding: 10px 0;
  border-radius: 10px;
}

.footer-cart button {
  height: 2.4em;
  font-size: 2rem;
  color: map-get($colors, contrast);
}

.overflow-scroll {
  overflow: scroll !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.overflow-scroll.hideScroll {
  overflow: hidden;
  overflow-y: hidden !important;
}

.order-header {
  .toolbar-background {
    background: var(--ion-color-secondary);
    border: 0;
    color: black;
  }

  .button-inner {
    color: var(--ion-color-primary);
  }

  .toolbar-title {
    color: #fff;
  }
}

/*--footer-button--*/

.group-select-label {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #666;

  ion-icon {
    margin-left: 0.5rem;
    font-size: 2rem;
    color: #222;
  }
}

.modal-largescreen {
  padding: 0 !important;
  --height: auto;
  --min-height: 80vh;
  --max-height: calc(100vh - 50px);

  .modal-wrapper {
    margin-top: auto;
    margin-bottom: 0;
  }
}
.modal-languages {
  div.modal-wrapper {
    width: 100%;
    height: auto;
    margin: 0 2rem;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    /* unset all default */
    transform: unset;
    opacity: unset;
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    background: unset;
    box-shadow: unset;
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    padding: unset;
  }
  app-language_choice{
    width: 100%;
    height: auto;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    display: unset;
    position: unset;
    flex-direction: unset;
    justify-content: unset;
    contain: unset;
    overflow: unset;
    z-index: unset;
  }
}
.modal-unlockpop {
  padding: 0 !important;
  --height: auto;
  --min-height: 80vh;
  --max-height: calc(100vh - 50px);

  .modal-wrapper {
    // margin-top: auto;
    // margin-bottom: 0;
    margin: 0 1rem !important;
  }
}

.variation-modal {
  --min-height: 75vh;
}

ion-modal {
  padding: 2%;
  background: rgba(0, 0, 0, 0.3);

  .content {
    background-color: transparent;
  }

  .scroll-content {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .scroll-content > * {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .modal-wrapper {
    box-shadow: none !important;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ion-page {
    position: relative;
  }

  ion-backdrop {
    //background:black;
    opacity: 0.3 !important;
    visibility: visible;
  }
}

.alert-wrapper {
  .alert-title {
    white-space: pre-line;
    //white-space: nowrap;
  }

  .alert-head {
    display: flex;
    justify-content: center;
  }

  .alert-message {
    white-space: pre-line;
    --max-height: none;
    max-height: none;
  }

  .alert-sub-title {
    font-size: var(--main-mid-text);
    white-space: normal;
  }

  .alert-button.alert-button-default {
    color: var(--ion-color-primary);
  }
}

.alert-wrapper-single-line {
  .alert-title {
    // white-space: pre-line;
    white-space: nowrap;
  }

  .alert-head {
    display: flex;
    justify-content: center;
  }

  .alert-message {
    white-space: pre-line;
    --max-height: none;
    max-height: none;
  }

  .alert-sub-title {
    font-size: var(--main-mid-text);
    white-space: normal;
  }

  .alert-button.alert-button-default {
    color: var(--ion-color-primary);
  }
}

.alert-content-row {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 0;
  border-bottom: 0.55px solid var(--ion-color-light);
  &:nth-child(2n) {
    background: var(--ion-color-light);
  }
  span:last-child {
    font-weight: bold;
  }
}

//// share cart
.shareCartToast {
  --width: 50%;
  // right: 0 !important;
  // left: unset !important;
}

//// for login form - CRM ////
.page-title {
  font-size: var(--main-lg-text);
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-bottom: 0;
}

.page-subtitle {
  font-size: var(--main-mid-text);
  color: var(--ion-color-medium);
  margin-top: 4px;
}

.user-info-box {
  border: 0;
  margin: 0;
  padding: 0;
  //// Information ////
  .input-desc-box {
    margin-top: 20px;
    text-align: center;

    p {
      margin: 0.5em 0;
      padding: 0 5%;
      font-size: var(--main-sm-text);
      color: var(--ion-color-medium);
    }

    ion-icon {
      font-size: 2rem;
      line-height: 1;
    }
  }
}

.form-box {
  .std-multi-input-row {
    display: inline-flex;
    width: 100%;

    .std-input-item {
      flex: 1;
    }
  }

  .std-input-item {
    --inner-border-width: 0;
    --padding-start: 0;
  }

  .std-input {
    border-radius: 8px;
    border: 0.55px solid var(--ion-color-medium);
    margin-bottom: var(--main-padding-sm);
    --padding-top: var(--main-padding);
    --padding-bottom: var(--main-padding);
    --padding-start: var(--main-padding);
  }

  .std-input-label {
    color: var(--ion-color-medium);
  }

  .label-offset {
    padding-left: var(--main-padding);
  }

  .input,
  .select,
  .datetime {
    padding-left: 16px;
    border: 0.55px solid #c8c7cc;
    background: #fff;
    border-radius: 8px;
  }

  .datetime {
    padding: 8px 8px 8px 16px;
  }

  .wrap-label {
    white-space: normal;
    font-size: 1.4rem;
  }

  .checkbox-icon {
    border-radius: 0;
  }

  .error-msg {
    color: red;
    max-height: 0em;
    margin: 0;
    order: 3;
    transition: 0.2s all ease-in-out;

    &.invalid {
      max-height: 1.3em;
      margin: 4px 6px;
    }
  }

  .flex-row {
    display: inline-flex;
    width: 100%;
  }
}

.phone-input-box {
  padding-top: var(--main-padding-sm);

  .phone-box {
    display: flex;
    flex-direction: row;
    min-height: auto;

    ion-input:first-child {
      margin-top: 4px;
      --padding-start: 8px;
      flex-grow: 2;
    }

    ion-input:last-child {
      flex-grow: 5;
      margin-top: 4px;
      margin-left: 8px;
      --padding-start: 8px;
    }

    .text-input {
      margin-left: 0;
      margin-top: 8px;
      // margin-bottom: 8px;
    }
  }
}

.checkbox-box {
  border-top: 1px solid var(--ion-color-medium);
  --inner-border-width: 0;

  ion-label {
    white-space: normal;
    font-size: var(--main-sm-text);
  }
}

//// for custom segment component
.cus-segment-wrapper {
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: var(--main-padding-sm);

  ion-icon {
    font-size: 1.4em;
    margin-right: 8px;
  }

  .button {
    flex: 1 1 50%;
    color: var(--ion-color-medium);
    margin: 0;
    padding: 8px 0;
    transition: 0.2s all ease-in-out;
    border-bottom: 2px solid #fff;

    &:first-child {
      border-radius: 0;
    }

    &.isActive {
      color: var(--ion-color-primary);
      border-bottom: 2px solid var(--ion-color-primary);
      font-weight: bold;
    }
  }
}

.mt2 {
  margin-top: 14px;
}

.mt4 {
  margin-top: 28px;
}

//// custom animation ////

// fade in right //
@-webkit-keyframes fadeInRights {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4%, 0, 0);
    transform: translate3d(4%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRights {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4%, 0, 0);
    transform: translate3d(4%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRights {
  -webkit-animation-name: fadeInRights;
  animation-name: fadeInRights;
  animation-duration: 0.6s;
}

// fade in left
@-webkit-keyframes fadeInLefts {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4%, 0, 0);
    transform: translate3d(-4%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLefts {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4%, 0, 0);
    transform: translate3d(-4%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLefts {
  -webkit-animation-name: fadeInLefts;
  animation-name: fadeInLefts;
  animation-duration: 0.6s;
}

.animated.shaking {
  padding-top: 2px;
  animation-name: shaking;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes shaking {
  0%,
  50%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(2px, 0);
    transform: translate(2px, 0);
  }
  75% {
    -webkit-transform: translate(-2px, 0);
    transform: translate(-2px, 0);
  }
}

.flashit {
  -webkit-animation: flashit linear infinite;
  animation: flashit 2s infinite;
}

@-webkit-keyframes flashit {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.97);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes flashit {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.97);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

//

.popped {
  animation: popped 1s;
  transition: 0.2s all ease-in-out;
}

@-webkit-keyframes popped {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

@keyframes popped {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

.pop {
  animation: pop 2s infinite;
  transition: 0.2s all ease-in-out;
}

@-webkit-keyframes pop {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.05);
  }
}

@keyframes pop {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.05);
  }
}

@-webkit-keyframes fadeInUps {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4%, 0);
    transform: translate3d(0, 4%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUps {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4%, 0);
    transform: translate3d(0, 4%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUps {
  -webkit-animation-name: fadeInUps;
  animation-name: fadeInUps;

  &.animated {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
  }
}

.floating {
  -webkit-animation: floating linear infinite;
  animation: floating 2s infinite;
}
/*--img-box--*/
.img-box {
  position: relative;
  //width: 100%;
  overflow: hidden;
  //background: url(/assets/image/no-image.jpg) no-repeat center !important;
  //background-size: cover !important;
  // background: lightgrey;
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.img-box:before {
  content: "";
  display: block;
  padding-top: var(--image-ratio);
}

.img-box img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: transparent;
  text-align: center;
  width: 100%;
}
.img-box ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes floating {
  0%,
  50%,
  100% {
    border-radius: 100px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.22);
  }

  25%,
  75% {
    border-radius: 100px;
    box-shadow: var(--main-key-btn-shadow);
  }
}

@keyframes small-pops {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.walking-around {
  -webkit-animation-name: walkingAround;
  animation-name: walkingAround;
  animation-iteration-count: infinite;
  transition: 0.1s all ease-in-out;
  animation-duration: 1s;
}

@keyframes walkingAround {
  0%,
  100% {
    transform: translate3d(5%, 0, 0);
  }
  25%,
  75% {
    transform: translate3d(0, 0%, 0);
  }
  50% {
    transform: translate3d(-5%, 0, 0);
  }
}

.alert-sub-title {
  white-space: pre;
}

/* English Font */
html:lang(en) {
  font-family: helve;

  * {
    font-family: helve;
  }
}

@font-face {
  font-family: helve;
  src: url(/assets/fonts/Helve.otf);
}
/* English Font */

/* Chinese font */
html:lang(zh) {
  font-family: Microsoft JhengHei;

  * {
    font-family: Microsoft JhengHei;
  }
}

// @font-face {
//   font-family: DFLiHei;
//   src: url(/assets/fonts/DFLiHei.ttc);
// }
