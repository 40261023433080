#cId5640198167724032 {
  page-direct-byod-splash {
    .language-btn-box {
      display: flex;
      justify-content: flex-end;
      ion-button {
        font-size: 13px;
      }
    }
    .info-container {
      position: absolute;
      left: 1rem;
      top: 1rem;
      width: auto;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .logo-img-box {
      width: 11.7rem;
      img {
        object-fit: cover;
        width: 11.7rem;
        height: 4.5rem;
      }
    }
    .shop-info-box {
      padding: 0.5rem;
    }
    .shop-info-box {
      padding: 0 4px;
      text-align: left;
    }
    .start-btn,
    .summary-button {
      white-space: normal;
      height: 3rem;
    }

    /* Grid */
    .muji-container {
      // background: yellow;
      display: grid;
     
      width: 100%;
      /* height: 40vh; */
      grid-template-columns: auto max-content;
      grid-template-rows: repeat(3, fr);

      grid-template-areas:
        "box01 box02"
        "box03 box04"
        "box05 box06";
      p {
        font-size: 1rem;
        margin: 0;
     
      }   
    }

    .muji-container:lang(en){

      grid-template-columns: auto max-content;   
      
    }

    .item{
      padding-bottom: 0.3rem;
      padding-left: 0.5rem;
    }

    .item.a {
      grid-area: box01;
      // background: red;
      text-align: right;
    }
    .item.b {
      grid-area: box02;
      color: var(--ion-color-primary);
      padding-right: 1rem;
      // background: blue;
    }
    .item.c {
      grid-area: box03;
      // background: green;
      text-align: right;
    }
    .item.d {
      grid-area: box04;
      color: var(--ion-color-primary);
      padding-right: 1rem;
      // background: orange;
    }
    .item.e {
      grid-area: box05;
      // background: grey;
      text-align: right;
    }
    .item.f {
      grid-area: box06;
      color: var(--ion-color-primary);
      padding-right: 1rem;
      // background: purple;
    }
  }

  ion-header {
    .ion-color-primary {
      --ion-color-primary: #6d6e71;
    }
    ion-toolbar {
      --background: #6d6e71;
    }
  }
  .start-btn-wrapper,
  .bottom-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom-btn-box {
    margin-bottom: 0.5rem;
  }
  .start-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .start-btn,
  .summary-button {
    width: 10rem;
    flex: 0 0 auto;
    height: 2.5em;
    font-size: 1rem !important;
  }
  .start-btn {
    // width: auto;
    min-width: 10rem;
    line-height: 1.2rem;
    background: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    flex-wrap: wrap;
  }
  .summary-btn {
    white-space: nowrap;
    width: auto;
  }
  div.direct-order-btn {
    margin-right: 0.3rem;
    white-space: pre-wrap;
    flex-shrink: 1;
    // width: auto;
    min-width: 9rem;
    height: auto;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-width: 10rem;
    width: 100%;
    p {
      margin: 0;
      color: #fff;
    }
    p.as-member {
      white-space: pre-line;
    }
  }
  ion-footer {
    .themeText {
      display: none;
    }
  }
}

.table-store-info {
  td {
    padding-bottom: 0.3rem;
  }
  .td-01,
  .td-03,
  .td-05 {
    width: auto;
    text-align: right;
    padding-right: 0.5rem;
  }
  .td-02,
  .td-04,
  .td-06 {
    color: var(--ion-color-primary);
    width: 7rem;
    p {
      padding-right: 1rem;
    }
  }
  p {
    margin: 0;
  }
}

// Cart

.section-reminder {
  padding-left: calc(var(--main-padding)*2);
  padding-right: calc(var(--main-padding)*2);
  margin-bottom: 2rem;
}

div.store-detail {
  //   padding-left: var(--main-padding);
  border-top: 1px solid #ececec;
  padding-top: 0.5rem;
  p {
    margin: 0;
  }
  .text-address {
    margin-top: 0;
    color: var(--ion-color-medium);
    font-size: var(--main-lg-text);
    margin-bottom: 0.2em;
    font-weight: bold;
  }
}

.reminder {
  //   padding-left: var(--main-padding);
  //   padding-right: var(--main-padding);
  .text-reminder {
    margin: 0;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    color: var(--ion-color-medium);
    text-align: justify;
  }
}


page-byod-summary{
  .AQA-callNo{
    font-size: 0.9rem !important;
    color: #000  !important;
  }
    
  .status-label{
    p{
    font-size: 0.9rem !important;
    color: #000 !important;
    }

    span{
      font-size: 0.9rem;
      
    }
  }    
}
